const border = {
  light: 'border border-gray-200 bg-gray-0 p-4 rounded-xl',
  dark: 'border border-gray-400 bg-gray-0 p-4 rounded-xl'
}

const table = {
  table: "!rounded-lg overflow-hidden",
  thead:
    "!bg-gray-50 !rounded-lg text-default-500 ring-1 ring-inset ring-gray-500/10 shadow-sm",
  th: "rounded-[6px] !h-11 text-gray-700",
  td: "text-gray-700 text-sm !py-3"
}

const input = {
  bordered: {
    input:
      "shadow-sm rounded-lg border border-default-200 bg-white hover:border-default-400 focus:border-default-600 focus-within:!border-default-600",
  },
  filled: {
    input:
      "rounded-lg !bg-gray-100 !border !border-transparent focus:!border focus:border-default-600 focus-within:!border-default-600",
  },
};

const badge = {
  light: 'text-gray-700 text-sm bg-[#00000008] px-2.5 py-1 rounded-lg',
  bordered: 'text-gray-700 text-base border-gray-300 p-3 rounded-lg',
  gray: "inline-flex max-w-fit items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10",
  red: "inline-flex max-w-fit items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10",
  yellow: "inline-flex max-w-fit items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20",
  green: "inline-flex max-w-fit items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
  blue: "inline-flex max-w-fit items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10",
  darkblue: "inline-flex max-w-fit items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10",
  purple: "inline-flex max-w-fit items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10",
  pink: "inline-flex max-w-fit items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10",
}

const button = {
  bordered: {
    label: "!text-gray-600",
    root: "!border !border-gray-200 !h-10 !bg-transparent hover:!bg-transparent hover:!border-gray-400 active:!border-gray-600",
  },
  dark: {
    label: "!text-white",
    root: "!h-10 !bg-gray-800 hover:!bg-gray-700",
  }, 
  gray: {
    label: "!text-default-600",
    root: "!h-10 !bg-gray-100 hover:!bg-gray-200",
  },
  transparent: {
    label: "!text-default-600",
    root: "!h-10 !bg-transparent hover:!bg-gray-100",
  },
  colored: {
    label: "!text-white",
    root: "!h-10 !bg-blue-500 hover:!bg-blue-600",
  },
};

const boxShadow = {
  cardShadow:
    'rgb(8 60 130 / 6%) 0px 0px 0px 0.05rem, rgb(30 34 40 / 4%) 0rem 0rem 1.25rem',
  cardHoverShadow: 'rgb(20 20 20 / 10%) 0px 8px 30px',
  popOverShadow: 'rgb(146 153 184 / 13%) 0px 5px 20px',
  lightShadow: 'rgb(146 153 184 / 8%) 0px 4px 16px',
  shadow: 'rgb(146 153 184 / 8%) 1px 2px 4px',
};

const layout = {
  mobile: '(max-width: 576px)',
  desktop: '(min-width: 1200px)',
};

const themeVariables = {
  border,
  badge,
  button,
  table,
  input,
  boxShadow,
  layout
};

export default themeVariables;