import ky from 'ky';
import { IKeywordsSearchDTO } from './dtos/keyword';
import { INaverPlaceInfo } from './dtos/place';
import { MembershipType } from '@/utils/payment';
import { IMembership, IPaymentHistory } from './dtos/membership';

export const CLIENT_ENDPOINT = process.env.NEXT_PUBLIC_CLIENT_ENDPOINT || 'https://www.definition-lab.com'
export const SERVER_ENDPOINT = process.env.NEXT_PUBLIC_SERVER_ENDPOINT || 'https://api.definition-lab.com'
export const STATIC_ENDPOINT = process.env.NEXT_PUBLIC_STATIC_ENDPOINT || 'https://static.definition-lab.com'

export const apiInstance = ky.create({
  prefixUrl: SERVER_ENDPOINT,
  credentials: 'include',
  timeout: 120000,
  retry: { limit: 2, delay: attemptCount => 2 ** attemptCount * 1000 },
  hooks: {
    afterResponse: [
			async (_request, _options, response) => {
        if (!response.ok) {
          const errMessage = await response.text()
          throw new Error(errMessage)
        }
			}
    ]
  },
})

export const API_ENDPOINT = {
  keyword: {
    search: async (keyword: string): Promise<IKeywordsSearchDTO> => {
      const result = await apiInstance.get(`search-tool/search?keyword=${encodeURIComponent(keyword)}`)
      const data = await result.json() as IKeywordsSearchDTO
      return data
    },
    getRecommendedKeyword: async () => {
      const result = await apiInstance.get(`search-tool/recommended-keyword`)
      const data = await result.json() as any
      return data
    },
  },
  place: {
    checkPlaceUrl: async (url: string) => {
      const result = await apiInstance.get(`my-place/check-place-url?url=${url}`)
      const data = await result.json() as any
      return data
    },
    getRegisterInfo: async () => {
      const result = await apiInstance.get(`my-place/register`)
      const data = await result.json() as any
      return data
    },
    registerMyPlace: async (url: string) => {
      const json = { url }
      const result = await apiInstance.post(`my-place/register`, { json })
      const data = await result.json() as any
      return data
    },
    getMyPlaceReport: async (url: string): Promise<INaverPlaceInfo> => {
      const result = await apiInstance.get(`my-place/report?url=${url}`)
      const data = await result.json() as INaverPlaceInfo
      return data
    },
    getMyPlacePreview: async (url: string): Promise<INaverPlaceInfo> => {
      const result = await apiInstance.get(`my-place/preview?url=${url}`)
      const data = await result.json() as INaverPlaceInfo
      return data
    },
    getMyPlaceSimpleInfo: async (url: string) => {
      const result = await apiInstance.get(`my-place/share?url=${url}`)
      const data = await result.json() as any
      return data
    },
    getCustomRankingKeywords: async () => {
      const result = await apiInstance.get(`my-place/custom-ranking-keywords`)
      const data = await result.json() as any
      return data
    },
    addCustomRankingKeywords: async (keyword: string) => {
      const json = { keyword }
      const result = await apiInstance.post(`my-place/custom-ranking-keywords`, { json })
      const data = await result.json() as any
      return data
    },
    deleteCustomRankingKeywords: async (keyword: string) => {
      return apiInstance.delete(`my-place/custom-ranking-keywords/${keyword}`)
    },
    getCustomRanking: async (keyword: string, placeId: string | undefined, placeType: string | undefined) => {
      const result = await apiInstance.get(`my-place/custom-ranking?keyword=${keyword}&place_id=${placeId}&place_type=${placeType}`)
      const data = await result.json() as any
      return data
    },
  },
  dashboard: {
    getKeywords: async () => {
      const result = await apiInstance.get(`dashboard/keywords`)
      const data = await result.json() as any
      return data
    },
    getPreviewKeywords: async (url: string) => {
      const result = await apiInstance.get(`dashboard/keywords/preview?url=${url}`)
      const data = await result.json() as any
      return data
    },
    getNewPlaces: async () => {
      const result = await apiInstance.get(`dashboard/new-places`)
      const data = await result.json() as any
      return data
    },
    getPreviewNewPlaces: async (url: string) => {
      const result = await apiInstance.get(`dashboard/new-places/preview?url=${url}`)
      const data = await result.json() as any
      return data
    },
    getRankingTrends: async () => {
      const result = await apiInstance.get(`dashboard/ranking-trends`)
      const data = await result.json() as any
      return data
    },
    getPreviewRankingTrends: async (url: string) => {
      const result = await apiInstance.get(`dashboard/ranking-trends/preview?url=${url}`)
      const data = await result.json() as any
      return data
    },
    getDashboardCustomRankingKeywords: async () => {
      const result = await apiInstance.get(`dashboard/custom-ranking-keywords`)
      const data = await result.json() as any
      return data
    },
    deleteDashboardCustomRankingKeyword: async (keyword: string) => {
      return apiInstance.delete(`dashboard/custom-ranking-keywords/${keyword}`)
    },
    addDashboardCustomRankingKeywords: async (keyword: string) => {
      const json = { keyword }
      const result = await apiInstance.post(`dashboard/custom-ranking-keywords`, { json })
      const data = await result.json() as any
      return data
    },
  },
  inquery: {
    sendServiceRequestInquery: async (data: Object) => await apiInstance.post(`inquery/service-request`, data),
    sendPosterRequestInquery: async (content: string) => await apiInstance.post(`inquery/poster-request`, { json: { content }}),
    sendErrorReport: async (content: any) => await apiInstance.post(`inquery/error-report`, { json: { content }}),
    sendConsultingRequest: async (content: any) => await apiInstance.post(`inquery/consulting-request`, { json: { content }})
  },
  account: {
    logout: async () => apiInstance.post(`account/logout`),
    checkUserUrl: `account/check`,
    checkUser: async () => {
      const result = await apiInstance.get(`account/check`, { retry: 0 })
      const data = await result.json() as any
      return data
    },
    registerUser: async (code: string, phone: number, newsletterGranted: boolean) => {
      const json = { code, phone, newsletter_granted: newsletterGranted }
      const result = await apiInstance.post(`account/register`, { json })
      const data = await result.json() as any
      return data
    },
    getUser: async () => {
      const result = await apiInstance.get(`account/get-user`)
      const data = await result.json() as any
      return data
    },
    updateNewsletterStatus: async (status: boolean) => {
      const json = { status }
      const result = await apiInstance.post(`account/newsletter`, { json })
      const data = await result.json() as any
      return data
    },
    updateKakaoTalkRankingChangeStatus: async (status: boolean) => {
      const json = { status }
      const result = await apiInstance.post(`account/kakaotalk/ranking-change`, { json })
      const data = await result.json() as any
      return data
    },
    updateKakaoTalkNewPlaceStatus: async (status: boolean) => {
      const json = { status }
      const result = await apiInstance.post(`account/kakaotalk/new-place`, { json })
      const data = await result.json() as any
      return data
    },
    withdraw: async () => {
      const result = await apiInstance.post(`account/withdrawal`)
      const data = await result.json() as any
      return data
    },
    sendVerificationCode: async (number: string) => {
      const json = { number }
      const result = await apiInstance.post(`account/verification-code/send`, { json, retry: 0 })
      const data = await result.json() as any
      return data
    },
    checkVerificationCode: async (number: string, code: string) => {
      const json = { number, code }
      const result = await apiInstance.post(`account/verification-code/check`, { json, retry: 0 })
      const data = await result.json() as any
      return data
    },
    getInvitationCode: async () => {
      const result = await apiInstance.get(`account/invitation-code`)
      const data = await result.json() as any
      return data
    }
  },
  membership: {
    getMembership: async (): Promise<IMembership> => {
      const result = await apiInstance.get(`membership`)
      const data = await result.json() as IMembership
      return data
    },
    getPaymentHistory: async (): Promise<IPaymentHistory> => {
      const result = await apiInstance.get(`membership/payment/history`)
      const data = await result.json() as IPaymentHistory
      return data
    },
    updateMembership: async (membership: MembershipType) => {
      const json = { membership }
      const result = await apiInstance.post(`membership/payment/change`, { json })
      const data = await result.json() as any
      return data
    },
    checkBillingKey: async () => {
      const result = await apiInstance.get(`membership/payment/check-billing-key`)
      const data = await result.json() as any
      return data
    },
    checkPromotionCode: async (code: string) => {
      const result = await apiInstance.get(`membership/payment/promotion?code=${code}`, { retry: 0 })
      const data = await result.json() as any
      return data
    },
    tossSuccessfulPaymentRedirectUrl: `${SERVER_ENDPOINT}/membership/payment/process/successful`,
    tossFailedPaymentRedirectUrl: `${SERVER_ENDPOINT}/membership/payment/process/failed`
  },
  posters: {
    getPosters: async (page: number = 1, category?: string) => {
      const url = `posters?page=${page}&${category ? `category=${category}` : ''}`
      const result = await apiInstance.get(url)
      const data = await result.json() as any
      return data
    },
    getPosterDetail: async (id: string) => {
      const result = await apiInstance.get(`posters/${id}`)
      const data = await result.json() as any
      return data
    },
    getCategories: async () => {
      const result = await apiInstance.get(`posters/categories`)
      const data = await result.json() as any
      return data
    },
  }
}