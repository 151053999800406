import toast from 'react-hot-toast';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

const Toast = {
  plain: (text: string) => {
    toast(text);
  },
  success: (text: string) => {
    toast.success(text);
  },
  error: (text: string) => {
    toast.error(text);
  },
  info: (text: string) => {
    toast(text, {
      icon: (
        <ExclamationCircleIcon
          className='h-5 w-5 text-blue-500'
        />
      ),
    });
  },
};

export default Toast;