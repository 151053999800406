import Toast from '@/components/Toast';
import { API_ENDPOINT } from '@/config/endpoint/endpoint';
import { TossPaymentsInstance, loadTossPayments } from '@tosspayments/payment-sdk'


export interface IMembershipPlan {
  type: MembershipType;
  label: string;
  price: string;
  description: string;
}

export type MembershipType = 'free' | 'plus' | 'pro' | 'prime';

export enum Membership {
  free = 'free',
  plus = 'plus',
  pro = 'pro',
  prime = 'prime'
}

export enum MembershipKorean {
  free = '프리',
  plus = '플러스',
  pro = '프로',
  prime = '프라임'
}

export enum MembershipPrice {
  free = 0,
  plus = 18900,
  pro = 22900,
  prime = 99900
}


export enum PaymentStatusKorean {
  requested = '요청',
  completed = '완료',
  canceled = '취소',
}

export const MEMBERSHIP_PLANS: IMembershipPlan[] = [
  { type: 'free', label: '프리', price: '무료', description: '먼저 체험해보고 결정해보세요'},
  { type: 'plus', label: '플러스', price: `${MembershipPrice.plus}원`, description: '기능의 제한없이 마음껏 이용해보세요'},
  { type: 'pro', label: '프로', price: `${MembershipPrice.pro}원`, description: '보다 꼼꼼하고 완벽하게 관리해보세요'}
]

export async function requestPayment(userId: string, membershipType: MembershipType, promotionCode?: string) {
  if (!userId || !membershipType) {
    Toast.error('유저ID가 잘못되었습니다. 새로고침 후에 다시 시도해주세요')
  }
  const clientKey = process.env.NEXT_PUBLIC_TOSS_CLIENT_KEY as string

  await loadTossPayments(clientKey).then((tossPayments: TossPaymentsInstance) => {
    tossPayments
      .requestBillingAuth('카드', {
        // https://docs.tosspayments.com/reference/js-sdk#requestbillingauth카드-결제-정보
        customerKey: userId,
        successUrl: `${API_ENDPOINT.membership.tossSuccessfulPaymentRedirectUrl}?membership=${membershipType}&promotion_code=${promotionCode}`,
        failUrl: API_ENDPOINT.membership.tossFailedPaymentRedirectUrl,
      })
      .catch(function (error) {
        // https://docs.tosspayments.com/reference/error-codes#결제창공통-sdk-에러
        if (error.code === 'USER_CANCEL') {
          Toast.info('결제가 정상적으로 처리되지 않았습니다.')
        }
      })
  })
}
